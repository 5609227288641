import React, { useEffect, useState } from "react";
import Link from 'gatsby-plugin-transition-link';
import AniLink from "gatsby-plugin-transition-link/AniLink";

import Layout from "../../../structure/Layout/Layout";
import Seo from "../../../structure/Seo/Seo";
import Editor from "../../../element/Editor/Editor";
import CitySkyline from "../../../element/Svg/CitySkyline";

import "./styles.scss";
import { clearLSFilters } from "../../../../utils/Data";

const TeamPage = () => {
  const [path, setPath] = useState("");

  useEffect(() => {
    clearLSFilters();
  }, []);

  return (
    <Layout current='a-propos' subcurrent='lequipe'>
      <Seo title="Équipe | Vu des Quartiers" />
      <div className="page page-grid page-cold page-team" data-theme="secondary-50">
        <div className="page_inner">

          <section className="section section-main section-page section-header mini section-description">
            <div className="section_inner radius radius-small radius-bottom radius-left" data-theme="primary">
              <div className="section-title">
                <h1 className="small"><b>L'équipe</b></h1>
              </div>
            </div>
            <CitySkyline />
          </section>

          <section className="section section-secondary section-page">
            <div className="section_inner radius radius-small radius-bottom radius-right" data-theme="white">
              <Editor content={`
              <div class="editor-block">
                <p>L’association Vu des Quartiers est une association loi 1901 à direction collégiale. Sa gestion au quotidien est assurée&nbsp;par un Conseil d’administration dont les membres sont&nbsp;désignés par l’Assemblée générale de l’association.</p>
              </div>
              <div class="editor-paragraph">
                <p>Toute rédaction de presse qui souhaite rejoindre le projet afin de participer à son développement et en assurer la pérennité peut demander à devenir membre actif de l’association Vu des Quartiers.<p>
              </div>
              <div class="editor-paragraph">
      <h3 class="wrapped"><span>L’association Vu des Quartiers a été cofondée par l’association des maires Ville & Banlieue et BFMTV</span></h3>
              </div>
              <div class="editor-paragraph">
                <h2>Sont membres d’honneur de l’association :</h2>
                <ul>
                  <li><div><span>Jérôme Bouvier</span>, fondateur et organisateur des Assises du journalisme, président de l’association « Journalisme et Citoyenneté »</div></li>
                  <li><div><span>Nadia Henni-Moulai</span>, journaliste indépendante et fondatrice du site Melting Book</div></li>
                  <li><div><span>Rémi Engrand</span>, directeur de cabinet et de la communication de la maire de Chanteloup-les-Vignes</div></li>
                  <li><div><span>Erwan Ruty</span>, journaliste, acteur associatif et directeur du Medialab93</div></li>
                </ul>
                </div>
                <div class="editor-paragraph">
                <h2>Animateur de l'association :</h2>
                <ul>
                  <li><div><span>Clément Vogt</span>, journaliste et data-journaliste indépendant, est animateur de l’association Vu des Quartiers.</div></li>
                </ul>
              </div>
            `} />

              <div className="editor">
                <div className="editor-paragraph outside">
                  <p>
                    Si vous souhaitez être référencé dans cet annuaire, parler de votre quartier, de vos actions,&nbsp;
                    <AniLink
                      fade
                      entry={{ delay: 0.5 }}
                      to={'/membre'}
                      className="link link-inherit">
                      <span className="link_label">inscrivez-vous !</span>
                    </AniLink>
                  </p>
                </div>
              </div>



            </div>
          </section>
        </div>
      </div>
    </Layout>
  );
}

export default TeamPage;